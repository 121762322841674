import { stringify } from 'qs'
import { map } from 'ramda'
import { AxiosInstance } from 'axios'
import { Context } from '@nuxt/types'
import { LocaleObject } from '@nuxtjs/i18n/types'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
// import { mock } from '~/lib/api/mock'
import { keyOfAuthToken } from '@/composables/useAuth'
import apiFactories from '~/lib/api/resources'
import { addCache } from '~/lib/api/cache'
import project from '~/project.config'

interface ApiFactory {
  (apiClient: AxiosInstance, context: Context): any
}

export default defineNuxtPlugin((context, inject) => {
  const {
    $axios,
    $config,
    ssrContext,
    $storage,
    app: { router, i18n },
    error,
  } = context

  addCache({
    $axios,
    $config,
    ssrContext,
    urlsToCache: project.axios?.urlsToCache
  })

  //
  // Axios intercetor
  //

  // Mock
  // const mockOptions = $config.MOCK_API ? { delayResponse: 3000 } : null
  // mock($axios, mockOptions)

  $axios.onRequest(config => {
    config.timeout = 60000

    // Array Params
    config.paramsSerializer = params => stringify(params, { arrayFormat: 'repeat' })

    // Auth
    const token = $storage.getCookie(keyOfAuthToken)
    if (token) {
      const normalizeToken = token.replace(/^Bearer /, '').trim()
      if (config.headers) {
        config.headers.Authorization = `Bearer ${normalizeToken}`
      }
    }

    // Force reload
    if (router) {
      const reload = router.currentRoute?.query?.reload
      if (reload) {
        config.params ??= {}
        config.params.reload = reload
      }
    }

    // I18n
    if (i18n) {
      const defaultLocaleISO = (i18n.locales as LocaleObject[])
        .find(locale => locale.code === i18n.defaultLocale)?.iso
      const currentLocaleISO = i18n.localeProperties?.iso
      // adapt `x-language` for your project
      const locale = currentLocaleISO || defaultLocaleISO
      if (config.headers && locale) {
        config.headers['x-language'] = locale
      }
    }
  })

  $axios.onError(({ response }) => {
    // 401 unauthorized
    if (response?.status === 401) {
      error({ statusCode: 401 })
    }
  })

  //
  // Inject API
  //
  inject('api', map((factory: ApiFactory) => factory(($axios as any) as AxiosInstance, context), apiFactories))
})
