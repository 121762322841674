export default {
  customer: {
    contactCentric: false
  },
  product: {
    details: {
      commonConnectionTypes: ['Interesting'],
      VariantConnectionTypes: ['Size', 'Color', 'Variant'],
    },
    viewableExcludeTypes: ['ProductVoucher'],
  },
  store: {
    availableStoreExcludeIds: [1],
  },
  checkout: {
    payment: {
      provider: 'Ogone'
    }
  },
  axios: {
    urlsToCache: [
      // '/api/vm/u',
      // '/api/sp/config',
      '/api/aspos/common/config',
      '/api/aspos/webnodes/main/root'
    ]
  }
}
