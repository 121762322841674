export interface VoucherType {
  id: number
  name?: string
}

export enum VoucherKind {
  Product = 'Product',
  Payment = 'Payment'
}

export interface Voucher {
  id: number
  name?: string
  code?: string
  type?: VoucherType
  paymentAmount?: number
  partialUsable?: boolean
  kind?: VoucherKind
  endDate?: string
}

export interface Coupon {
  id: number
  name?: string
}

export interface VoucherByCodeResult {
  coupon?: Coupon
  voucher?: Voucher
  message?: string
}

export interface VoucherResult {
  paymentVouchers: Voucher[]
  productVouchers: Voucher[]
}
