import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { useEvents } from '~/composables/framework/useEvents'
import { useDatalayer } from '~/composables/useDatalayer.ga4'

import { ProductEvents } from '~/composables/product/useProduct'
import { CartEvents } from '~/composables/useCart'
import {
  SearchEventArgs,
  ProductEventArgs,
  ProductListEventArgs
} from '~/composables/product/useProduct.e'
import {
  CartEventArgs,
  CartLinesEventArgs,
  SetPaymentEventArgs,
  OrderEventArgs
} from '~/composables/useCart.e'
import { WebNode } from '~/types/webNode'

export default defineNuxtPlugin(async (context) => {
  const { $gtm, nuxtState, app } = context

  // get root
  let root:(WebNode | null) = null
  if (nuxtState.ssrRefs) {
    const ssrRefs = nuxtState.ssrRefs
    const keys = Object.keys(ssrRefs)
    const rootKey = keys.find(key => key.includes('root'))
    if (rootKey) {
      root = ssrRefs[rootKey]
    }
  }
  if (!root) {
    root = await app.$api.webNode.getRoot(4)
  }

  const { on } = useEvents()
  const {
    onSearch,
    onViewProductList,
    onClickProduct,
    onViewProductDetails,

    onAddProducts,
    onRemoveProducts,
    onViewCart,

    onBeginCheckout,
    onSetShipment,
    onSetPayment,
    onPaid
  } = useDatalayer($gtm, root)

  // Product
  on(ProductEvents.Search, (e: SearchEventArgs) =>
    onSearch(e.term)
  )
  on(ProductEvents.ViewList, (e: ProductListEventArgs) =>
    onViewProductList(e.list, e.products)
  )
  on(ProductEvents.Click, (e: ProductEventArgs) =>
    onClickProduct(e.list, e.product, e.index)
  )
  on(ProductEvents.ViewDetails, (e: ProductEventArgs) => {
    onViewProductDetails(e.list, e.product)
  })

  // Cart
  on(CartEvents.AddProducts, (e: CartLinesEventArgs) =>
    onAddProducts(e.lines)
  )
  on(CartEvents.RemoveProducts, (e: CartLinesEventArgs) =>
    onRemoveProducts(e.lines)
  )
  on(CartEvents.View, (e: CartEventArgs) =>
    onViewCart(e.cart)
  )

  // Checkout
  on(CartEvents.Checkout, (e: CartEventArgs) =>
    onBeginCheckout(e.cart)
  )
  on(CartEvents.SetShipment, (e: CartEventArgs) =>
    onSetShipment(e.cart)
  )
  on(CartEvents.SetPayment, (e: SetPaymentEventArgs) =>
    onSetPayment(e.cart, e.payment)
  )
  on(CartEvents.Paid, (e: OrderEventArgs) =>
    onPaid(e.order, e.isNewCustomer)
  )
})
