import Vue from 'vue'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
// https://github.com/surmon-china/vue-awesome-swiper
// https://v1.github.surmon.me/vue-awesome-swiper/
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default defineNuxtPlugin(() => {
  Vue.use(VueAwesomeSwiper)
})
