export enum BrandImageType {
  Small = 'Small',
  Big = 'Big',
  Front = 'Front',
}

export interface BrandImageItem {
  id: number
  relatedId: number
  type: BrandImageType
  url: string
}

export interface Brand {
  id: number
  name: string
  url: string
  description?: string
  img?: string
}

export interface BrandImageAttr {
  width?: number
  height?: number
}
