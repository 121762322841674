import { Dictionary } from 'ramda'
import { ApiStatus } from './common'
import { NewsItem } from '~/types/blog'

export enum ContentPart {
  // GetMenus
  MainMenu = 'MainMenu',
  FooterMenu = 'FooterMenu',
  SubFooterMenu = 'SubFooterMenu',

  // GetMenusByName
  TopMenu = 'TopMenu',
  ServiceMenus = 'ServiceMenus',

  // BannerCategories
  SiteBanners = 'SiteBanners',
  HomeBanners = 'HomeBanners',
  HomeBannersRow = 'HomeBannersRow',
  HomeThreeBlocks = 'HomeThreeBlocks',
  HomeUspBanners = 'HomeUspBanners',
  HomeAdvantages = 'HomeAdvantages',
  MainUsps = 'MainUsps',
  VestigingBanners = 'VestigingBanners',
  CheckoutUSPs = 'CheckoutUSPs',

  // Banners
  DiscountBanners = 'DiscoutBanners',

  // Others
  CompanyServices = 'CompanyServices',
  WildPageMenu = 'WildPageMenu',
  Blocks = 'Blocks',
  NewsItems = 'NewsItems',
}

export enum Robots {
  NoIndex = 'noindex',
  NoFollow = 'nofollow',
}

export interface ContentRequest {
  pageMeta?: boolean
  selfLoad?: boolean
  type?: string
  pathUrl?: string
  objUrl?: string
  parts?: ContentPart[]
  generalDescription?: boolean
  banners?: string
}

export interface ContentBlock {
  id: number | string
  title?: string
  content?: string
  data?: any
  type: string
  typeId?: string
}

export interface PageMeta {
  name?: string
  title?: string
  type?: string
  description?: string
  img?: string
  keywords?: string
  link?: string
  domain?: string
  robots?: string
  locale?: string
  alternateLocales?: string[]
}

export interface CmsImage {
  src: string
  alt?: string
  alternateUrl?: string
  type?: string 
}

export interface Menu {
  name: string
  key: string
  url: string
  children?: Menu[]
  alternateUrl?: string
}

export interface Banner {
  name: string
  title?: string
  description?: string
  heading?: string
  linkTitle?: string
  url?: string
  alternateUrl?: string
  img?: string
}

export interface CmsArticle {
  title?: string
  text?: string
  description?: string
  summary?: string
  heading?: string
  customField1?: string
  customWidget1?: string
  customWidgetSetting1?: string
  robotsNoIndex?: boolean
  robotsNoFollow?: boolean

  banners?: Banner[]
  images?: CmsImage[]

  useBlocks?: boolean

  urlLocales?: Dictionary<string>
}

export enum SiteBannerFlags {
  ShowCart = 'showCart',
  ShowCheckout = 'showCheckout',
  ShowContent = 'showContent',
  ShowHomepage = 'showHomepage',
  ShowProduct = 'showProduct',
  ShowProductList = 'showProductList',
  ShowService = 'showService',
  ShowSiteWide = 'showSiteWide',
}

export interface SiteBanner {
  id: string
  title?: string
  text?: string
  cssBackgroundColor?: string
  cssTextColor?: string
  alternateUrl?: string
  linkTitle?: string
  dismissible?: boolean
  cssClass?: string
  showCart?: boolean
  showCheckout?: boolean
  showContent?: boolean
  showHomepage?: boolean
  showProduct?: boolean
  showProductList?: boolean
  showService?: boolean
  showSiteWide?: boolean
}

export type ContentPartResult =
  | Menu[]
  | Banner[]
  | ContentBlock[]
  | SiteBanner[]
  | NewsItem[]
  | null

export interface ContentResult {
  status: ApiStatus
  pageMeta?: PageMeta
  article?: CmsArticle
  parts?: Dictionary<ContentPartResult>
}

export interface ContentPartMeta {
  request: (req: any, params?: Dictionary<string>) => void
  response: (article: any) => ContentPartResult
}

export interface CompanyService {
  name: string
  field: string
}

export interface ContentLoadOptions {
  selfLoad?: boolean
  ensure?: boolean
  params?: Dictionary<string>
}

export interface ContentLoadContext {
  request: ContentRequest
  result?: ContentResult | null
}
